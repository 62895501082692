<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" :form="queryForm">
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderNo" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="下单人手机号">
              <a-input v-model="queryParam.mobile" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="运输状态">
              <a-select v-model="queryParam.transStatus" placeholder="请选择" default-value="">
<!--              <a-select  placeholder="请选择"  @change="changeTransStatus" v-decorator="['queryParam.transStatus']">-->
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">待调度</a-select-option>
                <a-select-option value="1">运输中</a-select-option>
                <a-select-option value="2">已到达</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业客户">
              <!--              <a-input v-model="queryParam.paymentCustomerCode" style="width: 100%" placeholder="客户识别码" />-->
              <a-select v-model="queryParam.paymentCustomerCode" placeholder="请选择" >
                <a-select-option v-for="item in qyCustomers" :key="item.code">{{ item.nickname }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="下单时间从：">
              <a-date-picker v-model="queryParam.orderDateStart" style="width: 100%" placeholder="请输入日期"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="到" class="dislodge-script">
              <a-date-picker v-model="queryParam.orderDateEnd" style="width: 100%" placeholder="请输入日期"/>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 4 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="importTransport()" v-action:but_less_trans_import>导入运单</a-button>&nbsp;&nbsp;
      <a-button type="primary" @click="updateStatus(5)" v-action:but_less_trans_status>到达</a-button>&nbsp;&nbsp;
      <a-button type="primary" @click="exportsTransport()" v-action:but_less_trans_exp>导出</a-button>&nbsp;&nbsp;
      <a-button @click="exportTemplate">模板下载</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content' ,y:scrollHeight}"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->

      <span slot="action" slot-scope="text, record" >
        <template>
          <a @click="orderInfo(record)" v-action:but_less_trans_orderinfo>详情&ensp;|&ensp;</a><!--如果需要权限 v-action:roleBD -->
          <a @click="openUpdateTransportNo(record)" v-action:but_less_trans_updatetransportno>{{record.transportNo==null?"关联运单号":"变更运单号"}}</a>
        </template>
      </span>
    </s-table>

    <a-modal
      key="importTransport"
      title="导入运单"
      :width="500"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{height:'auto'}"
    >
      <div>
        <a-form :form="form">
          <a-form-item
            label="选择"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-upload
              name="file"
              :max-count="1"
              class="avatar-uploader"
              :show-upload-list="false"
              @change="uploadChange"
              :headers="headers"
              action="/service_provider/order/importTransport"
            >
              <a-button id="showfilename">
                上传文件
              </a-button>
            </a-upload>

          </a-form-item>

          <div id="errormes" style="overflow-y: auto;height: 100px;color: red">

          </div>

        </a-form>
      </div>
    </a-modal>

    <a-modal
      key="updateTransportNo"
      title="关联运单号"
      :width="500"
      :visible="visible_add2"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel2"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{height:'auto'}"
    >
      <div class="waybill">
        <a-form @submit="handle2Submit" :form="form">
          <a-form-item
            label="请输入运单号"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
              v-decorator="[
            'transportNo',
            {rules: [{ required: true, message: '请输入单号' },{ pattern: /^[^\s]*$/, message: '请不要输入空格' }]}
          ]"
              name="transportNo"/>
          </a-form-item>
          <a-form-item
            label="请选择承运方"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-select
                placeholder="选择承运方"
                @change="handleChange2"
                v-decorator="[
                  'carrierId',
                  {rules: [{ required: true, message: '选择承运方' }]}
                ]"
              >
                <a-select-option v-for="(item, index) in carrierList" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            class="form-submit"
          >
            <a-button style="margin-right: 8px" @click="handleCancel2">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  findListCarrier,
  orderFindPage,
  batchTransDispacth,
  updateTransStatus, unLoadDispacth, findListCustomer
} from '@/api/manage'
import Vue from "vue";
import {ACCESS_TOKEN} from "@/store/mutation-types";
import axios from "axios";

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: {pageSizeOptions: ['50', '100','200','500']},
      treeData:[],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          width: '200px',
          key:'transactionNo',
          align: "center",
          dataIndex:'transactionNo',
          fixed: 'left',
        },
        {
          title: '运单号',
          width: '200px',
          key:'transportNo',
          align: "center",
          dataIndex:'transportNo',
        },
        {
          title: '最新物流节点',
          width: '300px',
          key:'nowTransInfo',
          align: "center",
          dataIndex:'nowTransInfo',
          customRender: (text) => {
            return <span class="col-sql" title={text}>{text}</span>
          },
        },
        {
          title: '线路',
          width: '200px',
          key:'lineName',
          align: "center",
          dataIndex:'lineName',
        },
        {
          title: '货物信息',
          width: '350px',
          key:'goodsInfo',
          align: "center",
          dataIndex:'goodsInfo'
        },
        {
          title: '状态',
          width: '100px',
          key:'transStatus',
          dataIndex:'transStatus',
          align: 'center',
          customRender: (text) => {
            let ret = ""
            if(text==0){
              ret = '待调度'
            }else if(text==1){
              ret = '运输中'
            }else if(text==2){
              ret = '已到达'
            }
            return ret
          }
        },
        {
          title: '下单时间',
          width: '200px',
          key:'orderDate',
          align: "center",
          dataIndex:'orderDate',
        },
        {
          title: '温度',
          width: '100px',
          key:'temperature',
          align: "center",
          dataIndex:'temperature',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '冷藏'
            }else if(text==2){
              ret = '冷冻'
            }else{
              ret = '常温'
            }
            return ret
          }
        },
        {
          title: '取消状态',
          width: '150px',
          key: 'revokeType',
          align: 'center',
          dataIndex: 'revokeType',
          customRender: (text) => {
            let ret = ''
            if (text !=null && text>0) {
              ret = '已取消'
            } else {
              ret = '正常'
            }
            return ret
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        let param = {};
        param = Object.assign(parameter, this.queryParam)
        param.type=2
        //param.transStatusIn=[0,1]

        if(this.queryParam!=null&&this.queryParam.transStatus!=null&&this.queryParam.transStatus!=""){
          param.transStatusIn = []
          param.transStatusIn.push(this.queryParam.transStatus)
        }
        param.loadStatusIn = [2]//已入库
        param.transportType=2
        if(this.queryParam.orderDateStart!=null){
          param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
        }
        if(this.queryParam.orderDateEnd!=null){
          param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
        }
        param.paymentCustomerCode = this.queryParam.paymentCustomerCode
        param.findPageOrderBys = "trans_status asc"
        let qtransStatus = this.$route.params.transStatus
        console.info("----------页面默认传参"+qtransStatus);
        if(param.transStatus==null&&qtransStatus!=null){
          param.transStatus = qtransStatus
          param.transStatusIn = []
          param.transStatusIn.push(qtransStatus)
        }
        return orderFindPage(param)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      confirmLoading:false,
      visible_add:false,
      visible_add2:false,
      form: this.$form.createForm(this),
      queryForm:this.$form.createForm(this),
      tabObj:{},//当前对象，编辑是用到
      addFlag: true,//true新增false编辑
      expandedKeys:[],//已绑定的keys
      submitKeys:[],//提交保存的key,包括半选中和选中
      thisOrder:{},
      scrollHeight:0,
      downloadurl:this.domain+"/glydtemlet.xlsx",
      carrierList:[],
      qyCustomers:[]
    }
  },
  created () {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight-455;
    findListCustomer({type:1})
      .then(res => {
        this.qyCustomers = []
        for(let i in res.result){
          this.qyCustomers.push(res.result[i])
        }

      }).catch((e)=>{
      this.$message.error("查询企业客户列表失败")
    })
  },
  activated() {
    let qtransStatus= this.$route.params.transStatus
    console.info("默认传参：qtransStatus："+qtransStatus)
    if(qtransStatus!=null){
      this.queryParam.transStatus = qtransStatus+""
    }else{
      this.queryParam = {}
    }
    this.$refs.table.refresh(true)
  },
  filters: {
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    updateStatus (status) {
      let that = this
      if(this.selectedRows.length<=0){
        this.$message.error("请选择订单")
        return false;
      }
      let param = {}
      param.type=2
      param.status=status//订单运输段完结状态为6
      param.orderIds=that.selectedRowKeys
      updateTransStatus(param)
        .then(res => {
          console.info(res.code)
          if(res.code==0){
            that.$message.success("更新成功")
            that.visible_add=false
            that.selectedRowKeys = []
            that.$refs.table.refresh()
          }else{
            that.$message.error(res.message)
          }
        })
    },
    exportsTransport(){
      let param = {};
      param = this.queryParam
      param.type=2
      //param.transStatusIn=[0,1]
      if(this.queryParam!=null&&this.queryParam.transStatus!=null&&this.queryParam.transStatus!=""){
        param.transStatusIn = []
        param.transStatusIn.push(this.queryParam.transStatus)
      }
      param.transportType=2
      if(this.queryParam.orderDateStart!=null){
        param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
      }
      if(this.queryParam.orderDateEnd!=null){
        param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
      }
      param.loadStatusIn = [2]//已入库

      let token = Vue.ls.get(ACCESS_TOKEN)
      axios({
        method: 'post',
        url: '/service_provider/order/export',
        data: param,
        responseType: 'blob',
        headers: {
          token: token,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = '订单数据.xls' //下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.exportShow = false
      })
    },
    changeTransStatus(e){
      console.info("--当前选中："+e)
      this.queryParam.transStatus = e
      this.queryForm.setFieldsValue({
        "queryParam.transStatus":e
      })

    },
    handleOk () {
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    importTransport () {
      let that = this
      this.visible_add = true
      that.$refs.table.refresh()
    },
    handle2Submit(e){
      e.preventDefault()
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = [];
          values.orderIds[0] = that.thisOrder.id
          values.type = 2
          console.log('Received values of form: ', values)
          batchTransDispacth(values).then(res =>{
            if(res!=null){
              console.info(res.code)
              if(res.code==0){
                that.$message.success("更新成功")
                that.visible_add2=false
                that.$refs.table.refresh()
              }else{
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    iscandel(record){
      if(record.type==1){
        return false
      }else{
        return  true
      }
    },
    handleCancel(){
      this.visible_add = false
    },
    uploadChange (info) {
      let that = this
      if (info.file.status === 'uploading') {
        this.loading = true
        return;
      }
      if (info.file.status === 'done') {
        //导入结果显示
        if(info.file.response.code==0){
          that.visible_add=false
          that.$refs.table.refresh()
        }else{
          document.getElementById("errormes").innerHTML  = info.file.response.message
        }

      }
    },
    openUpdateTransportNo(record){
        let that = this
        this.visible_add2 = true
        this.thisOrder = record
        this.carrierList=[]
        findListCarrier({status:1})
          .then(res => {
            for(let i in res.result){
              that.carrierList.push({id:res.result[i].id,name:(res.result[i].carrierName)})
            }
          })
    },
    handleCancel2(){
      this.visible_add2 = false
    },
    orderInfo(record){
      //this.$router.push({path:'/orderServe/orderManagerDetail', query: {"orderSn": record.orderSn}})
      this.$router.push({ name: 'order_detail_dis', query: { 'orderSn': record.transactionNo}})
    },
    exportTemplate(){
      axios({
        method: 'get',
        url: '/glydtemlet.xlsx',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = '模版.xlsx' //下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.exportShow = false
      })
    },
    handleChange2(value){
        this.form.setFieldsValue({
          name: value.name,
        })

    },
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        "token": token,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator{
  margin-bottom: 10px;
}
/deep/ .ant-form-item-label {
  width: 100px !important;
  text-align: left;
}
.ant-btn{
  line-height: 0 !important;
}
/deep/ .ant-table-fixed-columns-in-body{
    visibility: visible !important;
}
.waybill{
  /deep/ .ant-form-item-label {
  width: 120px !important;
  text-align: left;
 }
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}
.col-sql{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 299px;
}
</style>